<template>
  <div class="reserve-submit">
    <h3 class="title error-text">Invalid authorization data!</h3>
  </div>
</template>

<style lang="scss" scoped>
h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;

  &.error-text {
    background-color: rgba(red, 0.6);
  }

  &.success-text {
    background-color: rgba(green, 0.6);
  }
}
</style>

<script>
export default {
  name: "NoAuth",
};
</script>
